import { render, staticRenderFns } from "./NoEdited.vue?vue&type=template&id=76fa275c&"
import script from "./NoEdited.vue?vue&type=script&lang=js&"
export * from "./NoEdited.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VAlert,VDivider})
